window.addEventListener('load', ()=>{
    const wrappers = document.querySelectorAll('.seo-hidden-block');

    wrappers.forEach(wrapper => {
        const readMore = wrapper.querySelector('.seo-hidden-block .seo-nav');
    
        readMore.addEventListener('click', ()=> {
            wrapper.classList.toggle('open');
        });        
    });    
})
