// import Swiper JS
import Swiper from 'swiper';
import {Navigation, Pagination} from 'swiper/modules';

// import Swiper styles
import 'swiper/css';

window.addEventListener('load', ()=> {
    initSlider();
});

window.addEventListener('orientationchange', ()=> {
    initSlider();
})

function initSlider() {
    const wrapperSwiper = document.querySelector('.articles-slider-block');

    if (wrapperSwiper) {
        const swiperElement = wrapperSwiper.querySelector('.swiper');
        const buttonPrev = wrapperSwiper.querySelector('.swiper-button-prev');
        const buttonNext = wrapperSwiper.querySelector('.swiper-button-next');
    
        const slider = new Swiper(swiperElement, {
            modules: [Navigation, Pagination],
            slidesPerView: 1,
            spaceBetween: 15,
            observer: true,
            observeParents: true,
            // centerInsufficientSlides: true,
            // autoHeight: true,
            // centeredSlides: true,
            initialSlide: 2,
            breakpoints: {
                650: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                },
                1200: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                },
            },
    
            navigation: {
                prevEl: buttonPrev,
                nextEl: buttonNext,
            },
    
            pagination: {
                el: ".swiper-pagination",
                // clickable: true,
                dynamicBullets: true,
                // dynamicMainBullets: 3,
            },
        });
    
        slider;    
    }
}
